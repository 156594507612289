import React from "react";
import "./BottomModal.css";
import { Modal, Col, Row } from "react-bootstrap";

const BottomModal = ({ show, handleClose, planDetails }) => {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  // Create a new date object for the end date
  const todaysDate = new Date();
  const endDate = new Date();
  endDate.setDate(todaysDate.getDate() + 365 - 1); // Add 364 days (365 - 1)

  // Format the dates in DD MMM YYYY format
  const formattedEndDate = endDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <Modal
      centered
      show={show}
      keyboard={false}
      backdrop="static"
      onHide={handleClose}
      dialogClassName="bottom-modal p-3"
    >
      <Modal.Header closeButton>
        <Modal.Title className="plan-detail-heading-txt">
          Plan Details
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={3}>
            <p>
              <strong class="plan-detail-label-key-txt">Plan Name</strong>
            </p>
          </Col>
          <Col xs={9}>
            <p class="plan-detail-label-value-txt">
              {planDetails.plan_Description
                ? planDetails.plan_Description
                : "Protect"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <p>
              <strong class="plan-detail-label-key-txt">Duration</strong>
            </p>
          </Col>
          <Col xs={9}>
            <p class="plan-detail-label-value-txt">
              {planDetails.plan_Validity === "12" ? "1 Year" : "1 Year"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <p>
              <strong class="plan-detail-label-key-txt">Price</strong>
            </p>
          </Col>
          <Col xs={9}>
            <p class="plan-detail-label-value-txt">
              ₹{planDetails.plan_Amount ? planDetails?.plan_Amount : "699"}{" "}
              (Incl of GST)
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <p>
              <strong class="plan-detail-label-key-txt">Coverage</strong>
            </p>
          </Col>
          <Col xs={9}>
            <p class="plan-detail-label-value-txt">
              ₹
              {planDetails.plan_Description === "Protect"
                ? "5,000"
                : planDetails.plan_Description === "Protect Plus"
                ? "10,000"
                : "5,000"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <p>
              <strong class="plan-detail-label-key-txt">Start Date</strong>
            </p>
          </Col>
          <Col xs={9}>
            <p class="plan-detail-label-value-txt">
              {currentDate ? currentDate : "-"}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <p>
              <strong class="plan-detail-label-key-txt">End Date</strong>
            </p>
          </Col>
          <Col xs={9}>
            <p class="plan-detail-label-value-txt">
              {formattedEndDate ? formattedEndDate : "-"}
            </p>
          </Col>
        </Row>
      </Modal.Body>

      {/* <Modal.Footer className="d-flex justify-content-between">
        <Col lg={4} md={5} sm={11} xs={11}>
          <div className="fixed-buttons-container align-items-center">
            <div className="d-flex justify-content-center">
              <Col xs="auto" className="text-center">
                <div className="label-container">
                  <div className="label1">Buy Screen Protect</div>
                  <div className="label2">
                    <Button
                      variant=""
                      className="proceed-button"
                      onClick={handleShow}
                    >
                      View Plan Details
                    </Button>
                  </div>
                </div>
              </Col>
            </div>

            <div className="label3">
              <Button
                type="submit"
                variant="dark"
                className="pay-btn p-2"
                // style={{ width: "220px" }}
              >
                Pay ₹999
              </Button>
            </div>
          </div>
        </Col>
      </Modal.Footer> */}
    </Modal>
  );
};

export default BottomModal;
