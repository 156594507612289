import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "../styles.css";
import "./LandingPage.css";
import Header from "../../../../components/Header";
import ChooseUs from "../../../../components/ChooseUs";
import CopyRight from "../../../../components/CopyRight";
import FooterLogo from "../../../../components/FooterLogo";
import ProtectCard from "../../../../components/ProtectCard";
import BrandSwiper from "../../../../components/BrandSwiper";
import MyAccordion from "../../../../components/MyAccordion";
import CustomerReview from "../../../../components/CustomerReview/CustomerReview";
import ProfilePics from "../../../../assets/images/DimsMobile/ProfilePicsOne.svg";

const LandingPage = () => {
  return (
    <Container fluid className="gradient-bg">
      <div style={{ width: "96%", height: "100%" }}>
        {/* ****** Logo Container ****** */}
        <div style={{ width: "100%", alignItems: "center" }}>
          <Header />
        </div>

        {/* ****** Card Container ****** */}
        <div style={{ width: "100%", alignItems: "center" }}>
          <ProtectCard btnLabel="Protect My Screen" />
        </div>

        {/* ****** Why Choose Us Container ****** */}
        <div style={{ width: "100%", alignItems: "center" }}>
          <ChooseUs />
        </div>

        {/* ****** We've Got Protection For All Container ****** */}
        <div style={{ width: "100%", alignItems: "center", marginTop: 40 }}>
          <BrandSwiper />
        </div>

        {/* ****** Review Card Container ****** */}
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <div style={{ width: "100%" }}>
            <Col>
              <p className="happy-customer-review-heading-txt">
                Here's what our happy customers are saying
              </p>
              <p className="happy-customer-review-paragraph-txt">
                Learn why they choose us
              </p>
            </Col>

            <Row className="d-flex justify-content-center">
              <Col sm={12} md={6} lg={4} className="mb-2">
                <CustomerReview
                  name="Manya"
                  rating={4.3}
                  profilePic={require("../../../../assets/images/DimsMobile/Manya.png")}
                  review="Received my settlement within time frame and I am happy with the service. Process was transperent and hasslefree. Nice experience, relates to suggesting the service to friends and family."
                />
              </Col>

              <Col sm={12} md={6} lg={4} className="mb-2">
                <CustomerReview
                  rating={4.5}
                  name="Shivani"
                  profilePic={require("../../../../assets/images/DimsMobile/Shivani.png")}
                  review="My mobile phone fell in  water and screen is dead. Across Assist service center picked it from my home and got it repaired within a few days. Nice experience, will reccomend to my friends and family."
                />
              </Col>

              <Col sm={12} md={6} lg={4} className="mb-2">
                <CustomerReview
                  name="Ankur"
                  rating={4.2}
                  profilePic={require("../../../../assets/images/DimsMobile/Ankur.jpeg")}
                  review="This is a great product. Highly recommended! It exceeds expectations. I would buy it again. Quality is top-notch. Nice experience, relates to suggesting the service to friends and family."
                />
              </Col>

              {/* <Col sm={12} md={6} lg={4} className="mb-2">
                <CustomerReview
                  profilePic={ProfilePics}
                  name="John Doe"
                  review="This is a great product. Highly recommended!\nIt exceeds expectations.\nI would buy it again.\nQuality is top-notch."
                  rating={4.5}
                />
              </Col>

              <Col sm={12} md={6} lg={4} className="mb-2">
                <CustomerReview
                  profilePic={ProfilePics}
                  name="John Doe"
                  review="This is a great product. Highly recommended!\nIt exceeds expectations.\nI would buy it again.\nQuality is top-notch."
                  rating={4.5}
                />
              </Col>

              <Col sm={12} md={6} lg={4} className="mb-2">
                <CustomerReview
                  profilePic={ProfilePics}
                  name="John Doe"
                  review="This is a great product. Highly recommended!\nIt exceeds expectations.\nI would buy it again.\nQuality is top-notch."
                  rating={4.5}
                />
              </Col> */}
            </Row>
          </div>
        </div>

        {/* ****** You've Qestions Container ****** */}
        <div style={{ width: "100%", alignItems: "center" }}>
          <MyAccordion />
        </div>

        {/* ****** Footer Container ****** */}
        <div style={{ width: "100%", alignItems: "center", marginTop: 60 }}>
          <FooterLogo />
        </div>

        {/* ****** Copyright Container ****** */}
        <div style={{ width: "100%", alignItems: "center", marginBottom: 20 }}>
          <CopyRight />
        </div>
      </div>
    </Container>
  );
};

export default LandingPage;
