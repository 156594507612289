// import "./CopyRight.css";
// import React from "react";
// import { Container, Col } from "react-bootstrap";

// function CopyRight() {
//   const [termCondition, setTermCondition] = React.useState(false);
//   const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
//   const [siteMap, setSiteMap] = React.useState(false);

//   return (
//     <div style={{ display: "flex", justifyContent: "center" }}>
//       <div style={{ width: "95%" }}>
//         <Container
//           fluid
//           className="copy-right-logo-section display-flex justify-content-space-between"
//         >
//           {/* ****** Copyright Container ****** */}
//           <div className="flex-direction-row d-flex align-items-center">
//             <Col style={{ cursor: "pointer" }}>
//               <div className="copy-right-container-txt">
//                 Copyright © 2023 DMI Finance
//               </div>
//             </Col>
//           </div>

//           <div className="d-flex align-items-center flex-nowrap">
//             {/* ****** Terms and Conditions ****** */}
//             <Col
//               className="d-flex align-items-center"
//               style={{ cursor: "pointer" }}
//             >
//               <div
//                 style={{
//                   color: termCondition ? "#000" : undefined,
//                   fontWeight: termCondition ? "700" : undefined,
//                   textDecoration: termCondition ? "underline" : "none",
//                 }}
//                 onClick={() => {}}
//                 className="copy-right-container-txt"
//                 onMouseEnter={() => setTermCondition(true)}
//                 onMouseLeave={() => setTermCondition(false)}
//               >
//                 Terms and Conditions
//               </div>
//             </Col>

//             {/* ****** Vertical Line ****** */}
//             <Col xs="auto" className="d-flex justify-content-center">
//               <div className="copy-right-vertical-line mx-4" />
//             </Col>

//             {/* ****** Privacy Policy ****** */}
//             <Col className="d-flex align-items-center">
//               <div
//                 style={{
//                   color: privacyPolicy ? "#000" : undefined,
//                   fontWeight: privacyPolicy ? "700" : undefined,
//                   textDecoration: privacyPolicy ? "underline" : "none",
//                 }}
//                 onClick={() => {}}
//                 className="copy-right-container-txt"
//                 onMouseEnter={() => setPrivacyPolicy(true)}
//                 onMouseLeave={() => setPrivacyPolicy(false)}
//               >
//                 Privacy Policy
//               </div>
//             </Col>

//             <Col xs="auto" className="d-flex justify-content-center">
//               <div className="copy-right-vertical-line"></div>
//             </Col>

//             {/* ****** Sitemap ****** */}
//             <Col className="d-flex align-items-center">
//               <div
//                 style={{
//                   color: siteMap ? "#000" : undefined,
//                   fontWeight: siteMap ? "700" : undefined,
//                   textDecoration: siteMap ? "underline" : "none",
//                 }}
//                 onClick={() => {}}
//                 className="copy-right-container-txt"
//                 onMouseEnter={() => setSiteMap(true)}
//                 onMouseLeave={() => setSiteMap(false)}
//               >
//                 Sitemap
//               </div>
//             </Col>
//           </div>

//           {/* ****** Designed By Container ****** */}
//           <div className="d-flex flex-row align-items-center">
//             <Col className="d-flex align-items-center">
//               <div className="designed-by-container-txt">Designed by</div>
//               <div className="designed-by-screen-root-txt"> ScreenRoot</div>
//             </Col>
//           </div>
//         </Container>
//       </div>
//     </div>
//   );
// }

// export default CopyRight;
import "./CopyRight.css";
import React from "react";
import { Container, Col, Row } from "react-bootstrap";

function CopyRight() {
  const [termCondition, setTermCondition] = React.useState(false);
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
  // const [siteMap, setSiteMap] = React.useState(false);

  const openTermsAndConditions = () => {
    window.open("/assets/TermsAndConditions.pdf", "_blank");
  };

  const openPrivacyPolicy = () => {
    window.open("/assets/PrivacyPolicy.pdf", "_blank");
  };

  return (
    <div className="d-flex justify-content-center">
      <div style={{ width: "95%" }}>
        <Container fluid style={{}}>
          <Row>
            {/* ****** Copyright Container ****** */}
            <Col
              xs={12}
              sm={12}
              md={3}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="copy-right-container-txt">
                Copyright © 2023 DMI Finance
              </div>
            </Col>

            {/* ****** Links Container ****** */}
            <Col
              xs={12}
              md={6}
              className="mt-2 mb-2 d-flex flex-wrap align-items-center justify-content-center"
            >
              <div className="d-flex align-items-center flex-nowrap">
                <div className="d-flex align-items-center flex-nowrap">
                  {/* ****** Terms and Conditions ****** */}
                  <div
                    style={{
                      color: termCondition ? "#000" : undefined,
                      textDecoration: termCondition ? "underline" : "none",
                    }}
                    onClick={() => openTermsAndConditions()}
                    className="label-btn-txt"
                    onMouseEnter={() => setTermCondition(true)}
                    onMouseLeave={() => setTermCondition(false)}
                  >
                    Terms and Conditions
                  </div>

                  {/* ****** Vertical Line ****** */}
                  <div className="copy-right-vertical-line mx-4" />

                  {/* ****** Privacy Policy ****** */}
                  <div
                    style={{
                      color: privacyPolicy ? "#000" : undefined,
                      textDecoration: privacyPolicy ? "underline" : "none",
                    }}
                    onClick={() => openPrivacyPolicy()}
                    className="label-btn-txt"
                    onMouseEnter={() => setPrivacyPolicy(true)}
                    onMouseLeave={() => setPrivacyPolicy(false)}
                  >
                    Privacy Policy
                  </div>

                  {/* ****** Vertical Line ****** */}
                  {/* <div className="copy-right-vertical-line mx-4" /> */}

                  {/* ****** Sitemap ******
                  <Col
                    xs={12}
                    style={{
                      color: siteMap ? "#000" : undefined,
                      fontWeight: siteMap ? "700" : undefined,
                      textDecoration: siteMap ? "underline" : "none",
                    }}
                    onClick={() => {}}
                    className="label-btn-txt"
                    onMouseEnter={() => setSiteMap(true)}
                    onMouseLeave={() => setSiteMap(false)}
                  >
                    Sitemap
                  </Col> */}
                </div>
              </div>
            </Col>

            {/* ****** Designed By Container ****** */}
            <Col
              xs={12}
              md={3}
              lg={3}
              className="mt-2 d-flex flex-wrap align-items-center justify-content-center"
            >
              <div>
                <Col className="d-flex align-items-center">
                  <div className="designed-by-container-txt">Powered by</div>
                  <div className="designed-by-screen-root-txt">
                    {" "}
                    Across Assist
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CopyRight;
