import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Button, Card, Form } from "react-bootstrap";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import DeleteIcon from "../../../../assets/images/DimsMobile/DeleteIcon.svg";

import "../styles.css";
import "./ProtectForm.css";
import Header from "../../../../components/Header";
import Loader from "../../../../components/Loader";
import CopyRight from "../../../../components/CopyRight";
import GuideCard from "../../../../components/GuideCard";
import FooterLogo from "../../../../components/FooterLogo";
import BottomModal from "../../../../components/PlanPopup";
import CustomPopUp from "../../../../components/CustomPopUp";
import { DMI_FINANCE_APIS } from "../../../../config/ApiConfig";

import FindImei from "../../../../assets/images/DimsMobile/FindImei.svg";
import FileIcon from "../../../../assets/images/DimsMobile/FileIcon.svg";
import BackArrow from "../../../../assets/images/DimsMobile/BackArrow.svg";
import CrossIcon from "../../../../assets/images/DimsMobile/CrossIcon.svg";

const ProtectForm = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  // Define the media query for screen size below 'md'
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  // Media query to check if screen size is less and More than "md"
  const isLessThanMd = useMediaQuery({ minWidth: 768 });
  const isMoreThanMd = useMediaQuery({ maxWidth: 768 });

  const [showModal, setShowModal] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("protect");
  const [selectedPlanVariantId, setSelectedPlanVariantId] = useState("");
  const [selectedPlanAmount, setSelectedPlanAmount] = useState("");

  const protectFormInitialValues = {
    name: "",
    email: "",
    mobile: "",
    imeiNumber: "",
    brandName: "",
    modelName: "",
    zipCode: "",
    address: "",
    city: "",
    state: "",
    termsAgreed: false,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
    imeiNumber: Yup.string()
      .matches(/^[0-9]{15}$/, "Invalid IMEI number")
      .required("IMEI number is required"),
    brandName: Yup.string().required("Brand Name is required"),
    modelName: Yup.string().required("Model Name is required"),
    // terms: Yup.boolean()
    //   .oneOf([true], "You must accept the terms and conditions")
    //   .required("You must accept the terms and conditions"),
    zipCode: Yup.string()
      .matches(/^[0-9]{6}$/, "Postal Code must be exactly 6 digits")
      .required("Postal Code is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    termsAgreed: Yup.boolean()
      .oneOf([true], "You must agree to the Terms & Conditions")
      .required("You must agree to the Terms & Conditions"),
  });

  // ****** Handle Modal ******
  const handleIconClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // ****** Handle Radio Card ******
  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  // ****** Function to trigger file input when 'Browse File' is clicked ******
  const handleBrowseClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  // ****** Function to handle file upload ******
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      // Perform further actions with the selected file (e.g., uploading)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setIsFileSelected(reader.result);
      };
      reader.onerror = () => {};
    }
  };
  // ****** Function to handle file deletion ******
  const handleFileDelete = () => {
    setIsFileSelected(""); // Reset the file name
  };

  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [brandList, setBrandList] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [saveCustomerId, setSaveCustomerId] = useState(null);

  // Modal
  const [showBottomModal, setShowBottomModal] = useState(false);
  const viewPlanDetails = {
    name: "Protect",
    duration: "1 Year",
    price: "699",
    coverage: 5000,
    startDate: "7 Aug 2024",
    endDate: "6 Aug 2025",
  };
  const handleClose = () => setShowBottomModal(false);
  const handleShow = () => {
    setShowBottomModal(true);
  };

  // Handle card click
  const handleCardClick = (planData) => {
    setSelectedPlan(planData?.plan_Description);
    setSelectedPlanVariantId(planData?.plan_VarriantsId);
    setSelectedPlanAmount(planData?.plan_Amount);
    setSelectedPlanData(planData);
  };

  useEffect(() => {
    getDmiPlanListApi();
    getBrandListApi();
    // getPaymentUrlApi();
  }, []);

  useEffect(() => {
    if (planDetails?.length > 0) {
      const firstPlan = planDetails[0]?.plandetails?.[0]?.plan_Description;
      if (firstPlan) {
        setSelectedPlan(firstPlan);
      }
    }
  }, [planDetails]);

  useEffect(() => {
    if (planDetails?.length > 0) {
      setSelectedPlan(planDetails[0]?.plan_Description);
    }
  }, [planDetails]);

  // ****** Get Certificate List Api ******
  const getDmiPlanListApi = async () => {
    setLoading(true);

    axios(DMI_FINANCE_APIS.GET_PLAN_URL, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setPlanDetails(res?.data?.data);
          setLoading(false);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // ****** Get Brand List Api ******
  const getBrandListApi = async () => {
    setLoading(true);

    axios(DMI_FINANCE_APIS.GET_BRAND_LIST_URL, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setBrandList(res?.data?.data);
          setLoading(false);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // ****** Save DMI Details Api ******
  const handleSubmit = (values) => {
    setLoading(true);
    console.log("224 ==== isFileSelected ====", isFileSelected, termsAgreed);

    if (isFileSelected === "") {
      toast.error("Please select image to upload.");
    } else {
      const DMI_DETAILS = {
        Name: values?.name,
        Email: values?.email,
        MobileNumber: values?.mobile,
        IMEI: values?.imeiNumber,
        Brand: values?.brandName,
        Model: values?.modelName,
        PlanVarriantId: selectedPlanVariantId ? selectedPlanVariantId : "3215",
        MobileImg: isFileSelected,
        Pincode: values?.zipCode,
        Address: values?.address,
        City: values?.city,
        State: values?.state,
        Country: "India",
      };
      console.log(DMI_DETAILS);

      axios(DMI_FINANCE_APIS.SAVE_DMI_DETAILS_URL, {
        method: "POST",
        data: DMI_DETAILS,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setSaveCustomerId(res?.data?.customerid);
            // Storing a simple string
            localStorage.setItem("userId", res?.data?.customerid);
            sendDmiPaymentRequestApi({
              customerId: res?.data?.customerid,
              values: values,
            });
            toast.success(res?.data?.message);
            setLoading(false);
          }
          if (res?.data?.statusCode === 400) {
            toast.error(res?.data?.message);
            setLoading(false);
          }
          if (res?.data?.statusCode === 404) {
            toast.error(res?.data?.message);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  // ****** Send DMI Payemt Request Api ******
  const sendDmiPaymentRequestApi = ({ customerId, values }) => {
    setLoading(true);

    const SEND_DMI_PAYMENT_REQ_DATA = {
      UserId: customerId,
      Name: values?.name,
      Email: values?.email,
      MobileNumber: values?.mobile,
      Pincode: values?.zipCode,
      Address: values?.address,
      City: values?.city,
      State: values?.state,
      Country: "India",
      amount: selectedPlanAmount ? selectedPlanAmount : "699",
    };

    axios(DMI_FINANCE_APIS.SEND_DMI_PAYMENT_REQUEST_URL, {
      method: "POST",
      data: SEND_DMI_PAYMENT_REQ_DATA,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res?.status === 200) {
          // window.open(res?.data?.message, "_blank");
          window.open(res?.data?.message, "_self");
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // const openTermsAndConditions = () => {
  //   window.open("/assets/TermsAndConditions.pdf", "_blank");
  // };

  return (
    <Container fluid className="gradient-bg">
      <div className="auth-wrapper text-center">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div style={{ width: "96%", height: "100%" }}>
            {/* ****** Logo Container ****** */}
            <div style={{ width: "100%", alignItems: "center" }}>
              <Header />
            </div>

            {/* ****** Arrow & Heading Container ****** */}
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="back-arrow-img-container"
                onClick={() => navigate("/")}
              >
                <img
                  className="back-arrow-img"
                  src={BackArrow}
                  alt="BackArrow"
                />
              </div>

              <div className="back-arrow-title-container">
                <h1 className="back-arrow-title-txt">
                  Just a few quick details & your screen protection is set
                </h1>
              </div>
            </div>

            {/* ****** Guide & Plan Card Container ****** */}
            <div style={{ width: "100%", alignItems: "center" }}>
              <GuideCard selectedData={selectedPlanData} />
            </div>

            {/* ****** Personal Details Form Container ****** */}
            <div
              style={{
                width: "98%",
                // maxWidth: "1010px",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Formik
                // onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={protectFormInitialValues}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                    <h4 className="personal-details-heading-txt">
                      Personal Details
                    </h4>

                    {/* ****** Name & Email Container ****** */}
                    <Row className="mt-3">
                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="form-control font-family-Manrope"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              textAlign: "left",
                            }}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email ID"
                            className="form-control"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* ****** Mobile & IMEI Container ****** */}
                    <Row>
                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            className="form-control"
                            type="tel"
                            placeholder="Mobile"
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            isInvalid={!!errors.mobile}
                            maxLength={10}
                            inputProps={{ maxLength: 10 }}
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                            }}
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group position-relative mb-3">
                          <Field
                            className="form-control"
                            type="tel"
                            placeholder="IMEI"
                            name="imeiNumber"
                            value={values.imeiNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.imeiNumber}
                            maxLength={15}
                            inputProps={{ maxLength: 15 }}
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              paddingRight: "30px", // Add padding to avoid overlap with the icon
                            }}
                          />
                          <FaInfoCircle
                            className="position-absolute"
                            style={{
                              top: "50%",
                              right: "10px", // Adjust based on your needs
                              transform: "translateY(-50%)",
                              color: "#F69321",
                            }}
                            onClick={handleIconClick}
                          />
                          <ErrorMessage
                            name="imeiNumber"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* ****** Brand & Model Container ****** */}
                    <Row>
                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-3">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationFormik02"
                          >
                            <div
                              style={{ maxHeight: "150px", overflowY: "auto" }}
                            >
                              <Form.Control
                                as="select"
                                type="text"
                                name="brandName"
                                placeholder="Brand"
                                value={values.brandName}
                                onChange={handleChange}
                                style={{
                                  border: "none",
                                  borderRadius: 0,
                                  fontSize: "14px",
                                  backgroundColor: "transparent",
                                  borderBottom: "1px solid #BDBDBD",
                                }}
                              >
                                <option value="">Select Brand</option>
                                {brandList?.map((item) => (
                                  <option value={item?.value} key={item?.value}>
                                    {item?.value}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>
                          </Form.Group>
                          <ErrorMessage
                            name="brandName"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            type="text"
                            name="modelName"
                            placeholder="Model Name"
                            className="form-control font-family-Manrope"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              marginTop: "9px",
                            }}
                          />
                          <ErrorMessage
                            name="modelName"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* ****** ZipCode & Address  Container ****** */}
                    <Row>
                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group position-relative">
                          <Field
                            type="tel"
                            name="zipCode"
                            maxLength={6}
                            placeholder="Zipcode"
                            value={values.zipCode}
                            onChange={handleChange}
                            className="form-control mb-4"
                            isInvalid={!!errors.zipCode}
                            inputProps={{ maxLength: 6 }}
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              paddingRight: "30px", // Add padding to avoid overlap with the icon
                            }}
                          />
                          <ErrorMessage
                            name="zipCode"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={values?.address}
                            onChange={handleChange}
                            className="form-control font-family-Manrope"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              textAlign: "left",
                            }}
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* ****** City & State Container ****** */}
                    <Row>
                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            type="text"
                            name="city"
                            placeholder="City"
                            value={values?.city}
                            onChange={handleChange}
                            className="form-control font-family-Manrope"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              textAlign: "left",
                            }}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={11}>
                        <div className="form-group mb-4">
                          <Field
                            type="text"
                            name="state"
                            placeholder="State"
                            value={values?.state}
                            onChange={handleChange}
                            className="form-control font-family-Manrope"
                            style={{
                              border: "none",
                              borderRadius: 0,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #BDBDBD",
                              textAlign: "left",
                            }}
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textAlign: "left",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* ****** Select Plan Container ****** */}
                    <div>
                      <Row className="mt-1">
                        <h5 className="select-plan-heading-txt">Select Plan</h5>
                        {planDetails?.map((planData) => (
                          <Col
                            lg={4}
                            md={5}
                            sm={11}
                            key={planData?.plan_Description}
                          >
                            <Card
                              className={`p-3 ${
                                selectedPlan === planData?.plan_Description
                                  ? "border-primary"
                                  : ""
                              }`}
                              style={{
                                background:
                                  selectedPlan === planData?.plan_Description
                                    ? "#00B2E433"
                                    : "#00B2E41A",
                                borderRadius: "15px",
                                cursor: "pointer",
                                marginTop: "10px",
                              }}
                              // onClick={() => handleCardClick(planData)}
                              onClick={() => {
                                handleCardClick(planData);
                                setFieldValue(
                                  "plan",
                                  planData.plan_Description
                                ); // Update Formik
                              }}
                            >
                              <Form.Check
                                type="radio"
                                name="plan"
                                onChange={handlePlanChange}
                                className="protect-plus-txt"
                                id={planData?.plan_Description}
                                label={planData?.plan_Description}
                                value={planData?.plan_Description}
                                checked={
                                  selectedPlan === planData?.plan_Description
                                }
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <p className="mb-0 coverage-txt">
                                    Coverage of up to ₹
                                    {planData?.plan_Description ===
                                    "Protect Plus"
                                      ? "10,000"
                                      : "5,000"}
                                  </p>
                                </div>
                                <div>
                                  <h5 className="plan-amount-txt">
                                    ₹{planData?.plan_Amount}
                                  </h5>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>

                    {/* ****** Website- Upload Mobile Image ****** */}
                    {isLessThanMd && (
                      <Col
                        lg={8}
                        md={10}
                        sm={11}
                        className="upload-image-container"
                      >
                        <Form.Label
                          className="upload-mobile-image-heading-txt"
                          style={{ width: "100%" }}
                        >
                          Upload Mobile Image
                        </Form.Label>

                        {isFileSelected ? (
                          <Card
                            className="text-center p-4 d-flex justify-content-center align-items-center"
                            style={{
                              border: "2px dotted grey",
                              borderRadius: "15px",
                            }}
                          >
                            <img
                              className="me-3" // Add some space to the right of the image
                              alt="upload file"
                              src={isFileSelected}
                              style={{ width: 120 }}
                            />

                            <img
                              src={DeleteIcon}
                              className="mt-3"
                              alt="DeleteIcon"
                              onClick={handleFileDelete}
                              style={{ width: 30, cursor: "pointer" }} // Adjust size and make the icon clickable
                            />
                          </Card>
                        ) : (
                          <Card
                            className="text-center p-4 d-flex justify-content-center align-items-center"
                            style={{
                              border: "2px dotted grey",
                              borderRadius: "15px",
                            }}
                          >
                            {/* Hidden File Input */}
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />

                            {/* File Icon */}
                            <img
                              src={FileIcon}
                              className="mb-3"
                              alt="FileIcon"
                            />

                            {/* Heading */}
                            <div className="mb-2 upload-your-file-heading">
                              Choose a file or drag & drop it here.
                            </div>

                            {/* Subheading */}
                            <p className="text-muted mb-4">
                              JPEG, PNG PDF, and MP4 formats, up to 50 MB.
                            </p>

                            {/* Browse File Button */}
                            <Button
                              variant="link"
                              onClick={handleBrowseClick}
                              className="browse-file-btn-txt"
                              // style={{ textDecoration: "underline", color: "black" }}
                            >
                              Browse File
                            </Button>
                          </Card>
                        )}
                      </Col>
                    )}

                    {/* ****** Mobile Upload Mobile Image ****** */}
                    {isMoreThanMd && (
                      <Col
                        lg={8}
                        md={10}
                        sm={11}
                        className="upload-image-container"
                      >
                        <Form.Label
                          className="upload-mobile-image-heading-txt"
                          style={{ width: "100%" }}
                        >
                          Upload Mobile Image
                        </Form.Label>

                        <Card
                          className="text-center p-3 d-flex justify-content-center align-items-flex-start"
                          style={{
                            border: "1px dotted  rgba(0, 178, 228, 1)", // Soft border like in the design
                            borderRadius: "15px",
                            width: "100%",
                          }}
                        >
                          {isFileSelected ? (
                            <Row
                              style={{ height: "70px" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Col xs={10}>
                                <img
                                  alt="Selected File"
                                  src={isFileSelected}
                                  style={{ width: 40 }}
                                />
                              </Col>

                              <Col
                                xs={2}
                                // className="d-flex justify-content-flex-end flex-column"
                              >
                                <img
                                  src={DeleteIcon}
                                  className=""
                                  alt="DeleteIcon"
                                  onClick={handleFileDelete}
                                  style={{ width: 30, cursor: "pointer" }} // Adjust size and make the icon clickable
                                />
                              </Col>
                            </Row>
                          ) : (
                            <div className="d-flex align-items-center">
                              {/* Hidden File Input */}
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />

                              {/* File Icon */}
                              <img
                                src={FileIcon}
                                alt="Upload Document"
                                style={{ width: 40, marginRight: "10px" }} // Adjust margin for spacing
                              />

                              {/* Text Section */}
                              <Col
                                sm={9}
                                style={{ padding: "10px", textAlign: "left" }}
                              >
                                <div
                                  className="mb-2 upload-document-label-txt"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Upload document
                                </div>

                                {/* Browse File Button */}
                                <Button
                                  variant="link"
                                  onClick={handleBrowseClick}
                                  className="tap-to-upload-link-txt"
                                  style={{ padding: 0, fontSize: "14px" }}
                                >
                                  Tap to upload
                                </Button>
                              </Col>
                            </div>
                          )}
                        </Card>
                      </Col>
                    )}

                    {/* ****** Terms and Conditions ****** */}
                    <Form.Group
                      controlId="formBasicCheckbox"
                      className="mb-5 text-left" // Add text-left class for alignment
                      style={{ textAlign: "left", marginTop: 10 }}
                    >
                      {/* <Form.Check
                        type="checkbox"
                        label={
                          <p className="term-condition-txt-two">
                            I agree to the{" "}
                            <a
                              href="/assets/TermsAndConditions.pdf" // Correctly link to the PDF
                              target="_blank"
                              rel="noopener noreferrer" // Prevents security risks when opening new tabs
                              className="term-condition-txt"
                            >
                              Terms & Conditions
                            </a>
                          </p>
                        }
                        checked={termsAgreed}
                        // name="termsAgreed"
                        // onChange={handleChange}
                        onChange={(e) => setTermsAgreed(e.target.checked)}
                      />
                      <ErrorMessage
                        name="termsAgreed"
                        component="div"
                        className="text-danger"
                      /> */}
                      {/* <Form.Group
                        controlId="formBasicCheckbox"
                        className="mb-5 text-left"
                        style={{ textAlign: "left", marginTop: 10 }}
                      > */}
                      <Form.Check
                        type="checkbox"
                        label={
                          <p className="term-condition-txt-two">
                            I agree to the{" "}
                            <a
                              href="/assets/TermsAndConditions.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="term-condition-txt"
                            >
                              Terms & Conditions
                            </a>
                          </p>
                        }
                        name="termsAgreed"
                        checked={values.termsAgreed}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="termsAgreed"
                        component="div"
                        className="text-danger"
                        style={{ fontSize: "12px", marginTop: "-15px" }}
                      />
                      {/* </Form.Group> */}
                    </Form.Group>

                    {/* <Button
                      style={{ marginBottom: 200 }}
                      variant="outline-dark"
                      className="cancel-btn w-100 p-2"
                      // onClick={() => navigate("/payment-process")}
                      // onClick={() => navigate("/payment-success")}
                      // onClick={() => navigate("/payment-failure")}
                    >
                      Testing
                    </Button> */}

                    {/* ****** Large Screen Buttons ****** */}
                    <Row className="d-none d-md-flex">
                      <Col lg={4} md={5} sm={11}>
                        <Button
                          variant="outline-dark"
                          className="cancel-btn w-100 p-2"
                          onClick={() => navigate("/")}
                        >
                          Cancel
                        </Button>
                      </Col>

                      <Col lg={4} md={5} sm={11}>
                        <Row className="justify-content-center">
                          <Col>
                            <div className="custom-button2">
                              <Button
                                type="submit"
                                variant="dark"
                                className="proceed-to-pay-btn w-100 p-2"
                                // onClick={() => openCCAvenuePaymentGateway()}
                              >
                                Proceed To Pay
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* ****** Spacing Container ****** */}
                    <Col className="mb-4" style={{ height: "30px" }}></Col>

                    {/* ****** Fixed Button For Small Device ****** */}
                    <Col lg={4} md={5} sm={11} xs={11}>
                      {isSmallScreen && (
                        <div className="fixed-buttons-container align-items-center">
                          <div className="d-flex justify-content-center">
                            <Col xs="auto" className="text-center">
                              <div className="label-container">
                                <div className="label1">Buy Screen Protect</div>
                                <div className="label2">
                                  <Button
                                    variant=""
                                    className="proceed-button"
                                    onClick={handleShow}
                                  >
                                    View Plan Details
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </div>

                          <div className="label3">
                            <Button
                              type="submit"
                              variant="dark"
                              className="pay-btn p-2"
                            >
                              Pay ₹{" "}
                              {selectedPlanData?.plan_Amount
                                ? selectedPlanData?.plan_Amount
                                : "699"}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </FormikForm>
                )}
              </Formik>
            </div>

            {/* ****** Footer Container ****** */}
            <div
              className="d-none d-md-block"
              style={{ width: "100%", alignItems: "center", marginTop: 60 }}
            >
              <FooterLogo />
            </div>

            {/* ****** Copyright Container ****** */}
            <div
              className="d-none d-md-block"
              style={{ width: "100%", alignItems: "center", marginBottom: 20 }}
            >
              <CopyRight />
            </div>
          </div>
        )}
      </div>

      {/* {console.log("1133 ==== selectedPlanData ====", selectedPlanData)} */}

      {/* ****** IMEI PopUp Container ****** */}
      <BottomModal
        show={showBottomModal}
        handleClose={handleClose}
        planDetails={
          // selectedPlanData === null ? selectedPlanData : viewPlanDetails
          selectedPlanData === null ? viewPlanDetails : selectedPlanData
        }
      />

      {/* ****** IMEI PopUp Container ****** */}
      <CustomPopUp
        show={showModal}
        PopUpIcon={FindImei}
        ClosePopUpIcon={CrossIcon}
        heading="Find IMEI Number"
        handleClose={handleCloseModal}
        description="Dial *#06# to check the IMEI details of the mobile device you wish to insure."
      />
    </Container>
  );
};

export default ProtectForm;
