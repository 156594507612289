import React from "react";
import "./ChooseUs.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import SecurityIcon from "../../assets/images/DimsMobile/SecurityIcon.svg";
import SeamlessIcon from "../../assets/images/DimsMobile/SeamlessIcon.svg";
import FreePickup from "../../assets/images/DimsMobile/FreePickup.svg";
import CustomerSupport from "../../assets/images/DimsMobile/CustomerSupport.svg";

function ChooseUs() {
  return (
    <>
      <Row>
        <p className="why-choose-us-heading-txt">Why Choose Us</p>
      </Row>

      <Container fluid className="why-choose-us-card">
        <Row className="text-center">
          <Col className="mt-1" xs={6} md={3}>
            <Image
              src={SecurityIcon}
              alt="GreenTickIcon"
              className="choose-us-icon"
            />
            {/* <div className="label-txt">Service by brand</div> */}
            <div className="label-txt">Service by brand</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              certified technicians
            </div>
          </Col>

          <Col className="mt-1" xs={6} md={3}>
            <Image
              src={SeamlessIcon}
              alt="GreenTickIcon"
              className="choose-us-icon-two"
            />
            <div className="label-txt">Seamless & cashless</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              bill statement
            </div>
          </Col>

          <Col className="mt-1" xs={6} md={3}>
            <Image
              src={FreePickup}
              alt="GreenTickIcon"
              className="choose-us-icon-two"
            />
            <div className="label-txt">Free Pickup</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              & Delivery
            </div>
          </Col>

          <Col className="mt-1" xs={6} md={3}>
            <Image
              src={CustomerSupport}
              alt="GreenTickIcon"
              className="choose-us-icon"
            />
            <div className="label-txt">24x7 Customer</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              Support
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChooseUs;
