import React from "react";
import "./CustomPopUp.css";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const CustomPopUp = ({
  ClosePopUpIcon,
  PopUpIcon,
  heading,
  description,
  show,
  handleClose,
}) => {
  return (
    <Modal
      centered
      show={show}
      keyboard={false}
      backdrop="static"
      onHide={handleClose}
      dialogClassName="p-4"
    >
      <Modal.Body className="modal-body-content" onClick={handleClose}>
        <img
          alt="close"
          src={ClosePopUpIcon}
          onClick={handleClose}
          className="close-icon"
        />
      </Modal.Body>

      <Modal.Body className="text-center p-4">
        <img src={PopUpIcon} alt="find IMEI" />
        <h4 className="mb-3 heading-txt">{heading}</h4>
        <p className="description-txt">{description}</p>
      </Modal.Body>
    </Modal>
  );
};

export default CustomPopUp;
