import "./Payment.css";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Row, Col, Card, Alert, Button, Container } from "react-bootstrap";

const PaymentSuccess = () => {
  return (
    <Container className="text-center my-5">
      <Card className="borderless text-center" style={{ borderRadius: 8 }}>
        <Card.Body>
          <Row>
            <Col>
              <Alert variant="success">
                <Alert.Heading className="heading-txt">
                  Payment Successful!
                </Alert.Heading>
              </Alert>

              <FaCheckCircle size={130} color="green" className="mb-4 mt-4" />

              <p className="lead mt-4 sub-heading-txt">
                Thank you! Your payment has been processed successfully.
              </p>

              <Button
                variant="primary"
                href="/"
                className="mt-3 navigate-btn gradient-button"
              >
                Go to Home
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PaymentSuccess;
