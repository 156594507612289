import "./GuideCard.css";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import axios from "axios";
import { toast } from "react-toastify";
import { DMI_FINANCE_APIS } from "../../config/ApiConfig";
import { ReactComponent as FillDetailsIcon } from "../../assets/images/DimsMobile/FillDetailsIcon.svg";
import { ReactComponent as FindImei } from "../../assets/images/DimsMobile/FindImei.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/DimsMobile/UploadIcon.svg";
import { ReactComponent as WarningIcon } from "../../assets/images/DimsMobile/WarningIcon.svg";
// import WarningIcon from "../../assets/images/DimsMobile/WarningIcon.svg";
import { useMediaQuery } from "react-responsive";

// import FindImei from "../../assets/images/DimsMobile/FindImei.svg";
// import UploadIcon from "../../assets/images/DimsMobile/UploadIcon.svg";
// import FillDetailsIcon from "../../assets/images/DimsMobile/FillDetailsIcon.svg";

const GuideCard = ({ selectedData }) => {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const todaysDate = new Date();
  const endDate = new Date();
  endDate.setDate(todaysDate.getDate() + 365 - 1); // Add 364 days (365 - 1)

  const formattedEndDate = endDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  // Media query to check if screen size is less and More than "md"
  const isLessThanMd = useMediaQuery({ minWidth: 767 });
  const isMoreThanMd = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    getCertificateListApi();
  }, []);

  // ****** Get Certificate List Api ******
  const getCertificateListApi = async () => {
    setLoading(true);

    axios(DMI_FINANCE_APIS.GET_PLAN_URL, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setPlanDetails(res?.data?.data);
          setLoading(false);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="form-guide-card">
      <Row className="align-items-center">
        {/* ****** Guide Card Container ****** */}
        <Col lg={8} md={12}>
          <div className="form-fill-label-Txt-container">
            <div class="form-fill-label-Txt">
              Read this before you start filling the form
            </div>

            {/* ****** Fill Details Guide ****** */}
            <Row className="align-items-center">
              <Col lg={4} md={12}>
                <div className="d-flex flex-lg-column flex-row align-items-center guide-fill-container">
                  {/* <div className="d-flex flex-md-column flex-row align-items-center guide-fill-container"> */}
                  <FillDetailsIcon
                    width="40px"
                    height="40px"
                    style={{ marginBottom: "7px" }}
                  />
                  <div>
                    <div className="guide-fill-heading-Txt">
                      Fill in the details
                    </div>
                    <div className="guide-fill-sub-heading-Txt">
                      Open the link on another device and fill in the required
                      fields.
                    </div>
                  </div>
                </div>
              </Col>

              {/* ****** Check the IMEI ****** */}
              <Col lg={4} md={12}>
                {/* <div className="d-flex flex-md-column flex-row align-items-center guide-fill-container"> */}
                <div className="d-flex flex-lg-column flex-row align-items-center guide-fill-container">
                  <FindImei
                    width="40px"
                    height="40px"
                    style={{ marginBottom: "7px" }}
                  />
                  <div>
                    <div className="guide-fill-heading-Txt">Check the IMEI</div>
                    <div className="guide-fill-sub-heading-Txt">
                      Dial *#06# to check the IMEI details of the mobile device
                      you wish to insure.
                    </div>
                  </div>
                </div>
              </Col>

              {/* ****** Upload IMEI details ****** */}
              <Col lg={4} md={12}>
                {/* <div className="guide-fill-container">
                    <img src={UploadIcon} className="guide-fill-images" />
                  </div>
                  <div class="guide-fill-heading-Txt">Upload IMEI details</div>
                  <div class="guide-fill-sub-heading-Txt">
                    Upload a clear photo of the device's screen showing the IMEI
                    details.
                  </div> */}
                {/* <div className="d-flex flex-md-column flex-row align-items-center guide-fill-container"> */}
                <div className="d-flex flex-lg-column flex-row align-items-center guide-fill-container">
                  <UploadIcon
                    width="40px"
                    height="40px"
                    style={{ marginBottom: "7px" }}
                  />
                  <div>
                    <div className="guide-fill-heading-Txt">
                      Upload IMEI details
                    </div>
                    <div className="guide-fill-sub-heading-Txt">
                      Upload a clear photo of the device's screen showing the
                      IMEI details.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* ****** Screenshots Error Warning ****** */}
            <Col
              lg={4}
              md={12}
              className="screenshot-not-accept-warning-container"
            >
              <div className="d-flex  flex-row align-items-center warning-card-container">
                <WarningIcon width="20px" height="20px" />
                <div>
                  <div className="screenshot-not-accept-label-txt">
                    Screenshots are not accepted.
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col
              // lg={6} md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Row className="d-flex justify-content-center align-items-center">
                <div className="screenshot-not-accept-warning-container">
                  <Col
                    style={{ backgroundColor: "red" }}
                    className="warning-card-container text-center"
                    // className="warning-card-container text-center d-flex flex-column justify-content-center align-items-center"
                  >
                    <div className="d-flex flex-row align-items-center">
                      <WarningIcon width="20px" height="20px" />
                      <div className="screenshot-not-accept-label-txt">
                        Screenshots are not accepted.
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </Col> */}
          </div>
        </Col>

        {/* ****** Right Side: Image Content ****** */}
        <Col lg={4} md={12}>
          {/* <div className="d-none d-lg-block plan-details-card">
            <div class="plan-detail-heading-txt">Plan Details</div>

            <Row className="d-flex justify-content-center">
              <div className="plan-detail-container">
                <Col
                  style={{ width: "100%" }}
                  className="plan-name-value-container text-center"
                >
                  <p style={{ width: "30%" }} class="plan-detail-label-key-txt">
                    Plan Name
                  </p>
                  <p
                    style={{ width: "70%" }}
                    class="plan-detail-label-value-txt"
                  >
                    :{" "}
                    {selectedData?.plan_Description
                      ? selectedData?.plan_Description
                      : "DMI - Mobile Screen Protection -1 Year - 5K"}
                  </p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex justify-content-center">
              <div className="plan-detail-container">
                <Col
                  style={{ width: "100%" }}
                  className="plan-name-value-container text-center"
                >
                  <p style={{ width: "30%" }} class="plan-detail-label-key-txt">
                    Duration
                  </p>
                  <p
                    style={{ width: "70%" }}
                    class="plan-detail-label-value-txt"
                  >
                    :{" "}
                    {selectedData?.plan_Validity === "12" ? "1 Year" : "1 Year"}
                  </p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex justify-content-center">
              <div className="plan-detail-container">
                <Col
                  style={{ width: "100%" }}
                  className="plan-name-value-container text-center"
                >
                  <p style={{ width: "30%" }} class="plan-detail-label-key-txt">
                    Price
                  </p>
                  <p
                    style={{ width: "70%" }}
                    class="plan-detail-label-value-txt"
                  >
                    : ₹
                    {selectedData?.plan_Amount
                      ? selectedData?.plan_Amount
                      : " 0 "}{" "}
                    (incl of GST)
                  </p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex justify-content-center">
              <div className="plan-detail-container">
                <Col
                  style={{ width: "100%" }}
                  className="plan-name-value-container text-center"
                >
                  <p style={{ width: "30%" }} class="plan-detail-label-key-txt">
                    Coverage
                  </p>
                  <p
                    style={{ width: "70%" }}
                    class="plan-detail-label-value-txt"
                  >
                    : ₹
                    {selectedData?.plan_Description
                      ? selectedData?.plan_Description?.slice(-3)
                      : " 0 "}
                  </p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex justify-content-center">
              <div className="plan-detail-container">
                <Col
                  style={{ width: "100%" }}
                  className="plan-name-value-container text-center"
                >
                  <p style={{ width: "30%" }} class="plan-detail-label-key-txt">
                    Start Date
                  </p>
                  <p
                    style={{ width: "70%" }}
                    class="plan-detail-label-value-txt"
                  >
                    : {currentDate ? currentDate : "-"}
                  </p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex justify-content-center">
              <div className="plan-detail-container">
                <Col
                  style={{ width: "100%" }}
                  className="plan-name-value-container text-center"
                >
                  <p style={{ width: "30%" }} class="plan-detail-label-key-txt">
                    End Date
                  </p>
                  <p
                    style={{ width: "70%" }}
                    class="plan-detail-label-value-txt"
                  >
                    : {formattedEndDate ? formattedEndDate : "-"}
                  </p>
                </Col>
              </div>
            </Row>
          </div> */}

          <div className="d-none d-md-block mt-4">
            <div className="plan-details-card">
              <div className="plan-detail-heading-txt">Plan Details</div>
              {/* ****** Plan Name ****** */}
              <Row className="d-flex justify-content-center">
                <div className="plan-detail-container">
                  <Col
                    style={{ width: "100%" }}
                    className="plan-name-value-container d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{ width: "30%" }}
                      className="plan-detail-label-key-txt"
                    >
                      Plan Name
                    </p>
                    <p
                      style={{ width: "70%" }}
                      className="plan-detail-label-value-txt"
                    >
                      :{" "}
                      {selectedData?.plan_Description
                        ? selectedData?.plan_Description
                        : "Protect"}
                    </p>
                  </Col>
                </div>
              </Row>

              {/* ****** Duration ****** */}
              <Row className="d-flex justify-content-center">
                <div className="plan-detail-container">
                  <Col
                    style={{ width: "100%" }}
                    // className="plan-name-value-container text-center"
                    className="plan-name-value-container d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{ width: "30%" }}
                      className="plan-detail-label-key-txt"
                    >
                      Duration
                    </p>
                    <p
                      style={{ width: "70%" }}
                      className="plan-detail-label-value-txt"
                    >
                      :{" "}
                      {selectedData?.plan_Validity === "12"
                        ? "1 Year"
                        : "1 Year"}
                    </p>
                  </Col>
                </div>
              </Row>

              {/* ****** Price ****** */}
              <Row className="d-flex justify-content-center">
                <div className="plan-detail-container">
                  <Col
                    style={{ width: "100%" }}
                    // className="plan-name-value-container text-center"
                    className="plan-name-value-container d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{ width: "30%" }}
                      className="plan-detail-label-key-txt"
                    >
                      Price
                    </p>
                    <p
                      style={{ width: "70%" }}
                      className="plan-detail-label-value-txt"
                    >
                      : ₹{" "}
                      {selectedData?.plan_Amount
                        ? selectedData?.plan_Amount
                        : " 699 "}{" "}
                      (incl of GST)
                    </p>
                  </Col>
                </div>
              </Row>

              {/* ****** Coverage ****** */}
              <Row className="d-flex justify-content-center">
                <div className="plan-detail-container">
                  <Col
                    style={{ width: "100%" }}
                    // className="plan-name-value-container text-center"
                    className="plan-name-value-container d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{ width: "30%" }}
                      className="plan-detail-label-key-txt"
                    >
                      Coverage
                    </p>
                    {console.log(selectedData?.plan_Description)}
                    <p
                      style={{ width: "70%" }}
                      className="plan-detail-label-value-txt"
                    >
                      : ₹{" "}
                      {selectedData?.plan_Description === "Protect"
                        ? "5,000"
                        : !selectedData?.plan_Description
                        ? "5,000"
                        : "10,000"}
                    </p>
                  </Col>
                </div>
              </Row>

              {/* ****** Start ****** Date */}
              <Row className="d-flex justify-content-center">
                <div className="plan-detail-container">
                  <Col
                    style={{ width: "100%" }}
                    // className="plan-name-value-container text-center"
                    className="plan-name-value-container d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{ width: "30%" }}
                      className="plan-detail-label-key-txt"
                    >
                      Start Date
                    </p>
                    <p
                      style={{ width: "70%" }}
                      className="plan-detail-label-value-txt"
                    >
                      : {currentDate ? currentDate : "-"}
                    </p>
                  </Col>
                </div>
              </Row>

              {/* ****** End ****** Date */}
              <Row className="d-flex justify-content-center">
                <div className="plan-detail-container">
                  <Col
                    style={{ width: "100%" }}
                    // className="plan-name-value-container text-center"
                    className="plan-name-value-container d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{ width: "30%" }}
                      className="plan-detail-label-key-txt"
                    >
                      End Date
                    </p>
                    <p
                      style={{ width: "70%" }}
                      className="plan-detail-label-value-txt"
                    >
                      : {formattedEndDate ? formattedEndDate : "-"}
                    </p>
                  </Col>
                </div>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GuideCard;
