import axios from "axios";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";

const PaymentLoading = () => {
  const navigate = useNavigate();

  // Retrieving a simple string
  const userId = localStorage.getItem("userId");

  const [isLoading, setLoading] = useState("");

  useEffect(() => {
    getDmiPaymentResponseApi();
  }, []);

  const getDmiPaymentResponseApi = async () => {
    setLoading(true);

    axios(
      `https://portal.acrossassist.com//api/partner/v1/dmi/GetPaymentStatusByUserId?UserId=${userId}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        console.log("320 ==== success ====", res);
        if (res?.data?.data?.order_status === "Success") {
          navigate("/payment-success");
          setLoading(false);
        } else {
          navigate("/payment-failure");
          toast.success(res?.data?.status_message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Container
      fluid
      className="d-flex vh-100 justify-content-center align-items-center bg-light"
    >
      <Row>
        <Col xs={12} className="text-center">
          {/* Replace Spinner with your GIF if needed */}
          <Spinner
            role="status"
            variant="primary"
            animation="border"
            style={{ width: "4rem", height: "4rem" }}
          />
          {/* Alternatively, replace with an image */}
          {/* <img src="/path-to-your-gif.gif" alt="Loading..." style={{ width: '150px', height: '150px' }} /> */}

          <h3 className="mt-4">Processing Your Payment...</h3>
          <p className="text-muted">Please do not refresh the page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentLoading;
