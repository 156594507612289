// import "./FooterLogo.css";
// import React from "react";
// import { Container, Col, Row, Image } from "react-bootstrap";
// import DmiFinance from "../../assets/images/DimsMobile/DmiFinanceLogo.svg";
// import AcrossLogo from "../../assets/images/DimsMobile/AcrossAssistLogo.svg";

// function FooterLogo() {
//   const [dmiHover, setDmiHover] = React.useState(false);
//   const [acrossHover, setAcrossHover] = React.useState(false);

//   return (
//     <div style={{ display: "flex", justifyContent: "center" }}>
//       <div style={{ width: "95%" }}>
//         <hr />
//         <Container fluid className="footer-logo-section">
//           <Row className="align-items-center justify-content-space-between">
//             {/* ****** First Logo ****** */}
//             <Col
//               xs="auto"
//               className="d-flex justify-content-center mb-3 footer-container-logo"
//             >
//               <Image src={DmiFinance} alt="dmilogo" className="img-fluid" />
//             </Col>

//             {/* ****** Vertical Line ****** */}
//             {/* <Col xs="auto" className="d-flex justify-content-center mb-3">
//               <div className="footer-vertical-line"></div>
//             </Col> */}

//             {/* ****** Second Logo ****** */}
//             <Col
//               xs="auto"
//               className="d-flex justify-content-center mb-3 footer-container-logo"
//             >
//               <Image
//                 src={AcrossLogo}
//                 alt="Across Assist Logo"
//                 className="img-fluid"
//               />
//             </Col>
//           </Row>

//           <Row className="align-items-center justify-content-center mt-3">
//             {/* ****** About DMI Finance ****** */}
//             <Col
//               xs="auto"
//               className="d-flex justify-content-center mb-2 mb-md-0"
//             >
//               <div
//                 onClick={() => {}}
//                 className="about-label-txt"
//                 onMouseEnter={() => setDmiHover(true)}
//                 onMouseLeave={() => setDmiHover(false)}
//                 style={{ textDecoration: dmiHover ? "underline" : "none" }}
//               >
//                 About DMI Finance
//               </div>
//             </Col>

//             {/* ****** About Across Assist ****** */}
//             <Col
//               xs="auto"
//               className="d-flex justify-content-center mb-2 mb-md-0"
//             >
//               <div
//                 onClick={() => {}}
//                 className="about-label-txt"
//                 onMouseEnter={() => setAcrossHover(true)}
//                 onMouseLeave={() => setAcrossHover(false)}
//                 style={{ textDecoration: acrossHover ? "underline" : "none" }}
//               >
//                 About Across Assist
//               </div>
//             </Col>
//           </Row>
//         </Container>
//         <hr />
//       </div>
//     </div>
//   );
// }

// export default FooterLogo;

import "./FooterLogo.css";
import React from "react";
import { Row, Col } from "react-bootstrap";
import DmiFinance from "../../assets/images/DimsMobile/DmiFinanceLogo.svg";
import AcrossLogo from "../../assets/images/DimsMobile/AcrossAssistLogo.svg";

const FooterLogo = () => {
  const [dmiHover, setDmiHover] = React.useState(false);
  const [acrossHover, setAcrossHover] = React.useState(false);

  const openDmiFinance = () => {
    window.open("https://www.dmifinance.in/about-us/about-company/", "_blank");
  };

  const openAboutAcrossAssist = () => {
    window.open("/assets/AboutAcrossAssist.pdf", "_blank");
  };

  return (
    <div>
      {/* Row for logo and buttons */}
      <Row className="align-items-center mb-4">
        {/* Below md: Two logos in the same row, and buttons in another row */}
        {/* Above md: Everything in a single row with space between items */}

        {/* Logos */}
        <Col xs={6} md={3} className="text-center">
          <img
            src={DmiFinance}
            alt="dmilogo"
            className="img-fluid footer-logo-img-dmi"
            // style={{ width: "100%" }}
          />
        </Col>
        <Col xs={6} md={3} className="text-center">
          <img
            src={AcrossLogo}
            className="img-fluid footer-logo-img"
            alt="Across Assist Logo"
          />
        </Col>

        {/* Buttons */}
        <Col xs={6} md={3} className="text-right mt-3 mt-md-0">
          <div
            onClick={() => openDmiFinance()}
            className="about-label-txt"
            onMouseEnter={() => setDmiHover(true)}
            onMouseLeave={() => setDmiHover(false)}
            style={{ textDecoration: dmiHover ? "underline" : "none" }}
          >
            About DMI Finance
          </div>
        </Col>

        <Col xs={6} md={3} className="text-right mt-3 mt-md-0">
          <div
            className="about-label-txt"
            onClick={() => openAboutAcrossAssist()}
            onMouseEnter={() => setAcrossHover(true)}
            onMouseLeave={() => setAcrossHover(false)}
            style={{ textDecoration: acrossHover ? "underline" : "none" }}
          >
            About Across Assist
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FooterLogo;
