import "./Header.css";
import React from "react";
import { Container, Col, Image } from "react-bootstrap";
import DmiFinance from "../../assets/images/DimsMobile/DmiFinanceLogo.svg";
import AcrossLogo from "../../assets/images/DimsMobile/AcrossAssistLogo.svg";

function Header() {
  return (
    <Container fluid className="header-section">
      <div className="flex-direction-row d-flex align-items-center">
        {/* ****** First Logo ****** */}
        <Col>
          <Image src={DmiFinance} alt="dmilogo" className="img-fluid" />
        </Col>

        {/* ****** Vertical Line ****** */}
        <Col xs={2} className="text-center">
          <div className="vertical-line mx-4"></div>
        </Col>

        {/* ****** Second Logo ****** */}
        <Col xs={4} className="text-left">
          <Image
            src={AcrossLogo}
            className="img-fluid"
            alt="Across Assist Logo"
          />
        </Col>
      </div>
    </Container>
  );
}

export default Header;
