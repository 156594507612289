import React from "react";
import "./MyAccordian.css";
import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const MyAccordion = () => {
  return (
    <div
      style={{
        display: "flex",
        borderRadius: 6,
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <Col>
          <p className="questions-heading-txt">
            You’ve got questions, we’ve got answers
          </p>
        </Col>

        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                What is covered under Mobile Screen Protection Service?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body accordian-answer-txt">
                Mobile Screen Protection covers accidental damage to the screen,
                such as cracks or shatters due to drops or impacts and liquid
                damage, which can occur if the phone is exposed to water or
                other liquids.
              </div>
            </div>
          </div>

          {/* ****** Question 1 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                What is the Sum Assured Amount and Premium for the service?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                The Sum Assured is up to INR 10,000 for a premium of INR 999 per
                annum and INR 5000 for a premium of INR 699. If the repair cost
                is more than INR 5000/10000 as per the plan, then the customer
                must bear over and above cost to Across Assist.
              </div>
            </div>
          </div>

          {/* ****** Question 2 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
              >
                When will the Mobile Screen Protection Plan get started?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Once all the details are submitted correctly and the premium
                payment is successfully made, the plan will be activated.
              </div>
            </div>
          </div>

          {/* ****** Question 3 ****** */}

          {/* ****** Question 4 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseFive"
              >
                How can the IMEI Number be fetched?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseFive"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                To fetch the IMEI Number dial *#06# on your Mobile phone.
              </div>
            </div>
          </div>

          {/* ****** Question 5 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFour"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseFour"
              >
                What happens to the Service in case the correct image and
                details are not submitted for the Subscription?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseFour"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Your Policy will be cancelled, and the full Premium amount paid
                shall be refunded to the customer.
              </div>
            </div>
          </div>

          {/* ****** Question 6 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseSix"
              >
                What is the period of coverage?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseSix"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Mobiles will be covered for the period of 1 Year from the date
                of policy purchased.
              </div>
            </div>
          </div>

          {/* ****** Question 7 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSeven"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseSeven"
              >
                What is the age limit for the customers who can opt for the
                Service?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseSeven"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Age of the Customer should be 18 years or above.
              </div>
            </div>
          </div>

          {/* ****** Question 8 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseEight"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseEight"
              >
                How many times can the Customer claim during the Policy Period?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseEight"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Customer shall take one claim during the entire policy period.
              </div>
            </div>
          </div>

          {/* ****** Question 9 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseNine"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseNine"
              >
                What is the Claim process of the Policy?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseNine"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Contact ACROSS ASSIST Alarm Center by phone or email to report
                the damage incident on following numbers.
                <br />
                Customers must report any incidents within 72 hours, by either
                calling the toll-free number
                <br />
                0120-4501449 or emailing dmi.finance@acrossassist.com. Upon
                reporting, the customer will receive a unique claim number. The
                claim process outlined below:
                <br />
                <br />
                1. Across Assist will collect information and request relevant
                documents from the customer, including the policy copy, Incident
                details, and KYC.
                <br />
                <br /> 2. Across Assist will coordinating with the customer to
                arrange the pick-up of the device.
                <br />
                <br /> 3. Across Assist will inform the customer of the
                estimated loss, and any repair costs exceeding the fixed benefit
                amount must be borne by the customer.
                <br />
                <br /> 4. Upon the customer's confirmation, Across Assist will
                authorize the service centre to proceed with the repair. 5. The
                repaired device will be delivered to the customer within 10-14
                working days.
              </div>
            </div>
          </div>

          {/* ****** Question 10 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTen"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTen"
              >
                Is there any waiting/cooling period?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTen"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                A waiting period of 7 days is applicable.
              </div>
            </div>
          </div>

          {/* ****** Question 11 ****** */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed accordian-question-heading-txt"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseEleven"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseEleven"
              >
                Are there any deductibles/excess fee?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseEleven"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body accordian-answer-txt">
                Excess (Deductibles)- 2% of the screen value (at the time of
                service) is applicable.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccordion;
