import React from "react";
import "./BrandSwiper.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import SamsungBrand from "../../assets/images/DimsMobile/SamsungBrand.svg";
import AppleBrand from "../../assets/images/DimsMobile/AppleBrand.svg";
import NothingBrand from "../../assets/images/DimsMobile/NothingBrand.svg";
import MotorollaBrand from "../../assets/images/DimsMobile/MotorollaBrand.svg";
import OppoBrand from "../../assets/images/DimsMobile/OppoBrand.svg";
import { useMediaQuery } from "react-responsive";

const BRAND_DATA = [
  {
    id: "1",
    brand: "Samsung",
    brandLogo: SamsungBrand,
  },
  {
    id: "2",
    brand: "Apple",
    brandLogo: AppleBrand,
  },
  {
    id: "3",
    brand: "Nothing",
    brandLogo: NothingBrand,
  },
  {
    id: "4",
    brand: "Motorola",
    brandLogo: MotorollaBrand,
  },
  {
    id: "5",
    brand: "Oppo",
    brandLogo: OppoBrand,
  },
];

function BrandSwiper() {
  const handleImageClick = (data) => {
    console.log("Image clicked", data);
    // You can perform actions here, like navigating to a new page, showing a modal, etc.
  };

  return (
    <>
      <Row>
        <p className="protection-for-all-heading-txt">
          We've got protection for all
        </p>
      </Row>

      <Container
        style={{ backgroundColor: "transparent" }}
        fluid
        className="protection-for-all-card"
      >
        <Row className="text-center  d-flex justify-content-center align-items-center">
          {BRAND_DATA?.map((data, index) => {
            return (
              <Col key={index} lg={2} md={2} sm={4} xs={4} className="mt-1">
                <div onClick={() => handleImageClick(data)}>
                  <Image
                    alt="brandlogo"
                    src={data?.brandLogo}
                    className="brand-icon"
                    style={{
                      border: "1px solid  rgba(0, 178, 228, 0.2)",
                      borderRadius: "10px",

                      // padding: "5px",
                    }}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default BrandSwiper;
// import React from "react";
// import { Carousel } from "react-bootstrap";
// // import "./BrandSwiper.css";
// import SamsungBrand from "../../assets/images/DimsMobile/SamsungBrand.svg";
// import AppleBrand from "../../assets/images/DimsMobile/AppleBrand.svg";
// import NothingBrand from "../../assets/images/DimsMobile/NothingBrand.svg";
// import MotorollaBrand from "../../assets/images/DimsMobile/MotorollaBrand.svg";
// import OppoBrand from "../../assets/images/DimsMobile/OppoBrand.svg";

// const BrandSwiper = () => {
//   return (
//     <Carousel>
//       <Carousel.Item>
//         <img className="d-block" src={SamsungBrand} alt="First slide" />
//       </Carousel.Item>
//       <Carousel.Item>
//         <img className="d-block" src={AppleBrand} alt="Second slide" />
//       </Carousel.Item>
//       <Carousel.Item>
//         <img className="d-block" src={NothingBrand} alt="Third slide" />
//       </Carousel.Item>
//       <Carousel.Item>
//         <img className="d-block" src={MotorollaBrand} alt="Third slide" />
//       </Carousel.Item>

//       <Carousel.Item>
//         <img className="d-block" src={OppoBrand} alt="Third slide" />
//       </Carousel.Item>
//     </Carousel>
//   );
// };

// export default BrandSwiper;
