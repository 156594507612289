import React from "react";
import "../PaymentSuccess/Payment.css";
import { FaTimesCircle } from "react-icons/fa";
import { Row, Col, Card, Alert, Button, Container } from "react-bootstrap";

const PaymentFailure = () => {
  return (
    <Container className="text-center my-5">
      <Card className="borderless text-center" style={{ borderRadius: 8 }}>
        <Card.Body>
          <Row>
            <Col>
              <Alert variant="success">
                <Alert.Heading className="heading-txt">
                  Payment Failed!
                </Alert.Heading>
              </Alert>

              <FaTimesCircle size={100} color="red" className="mb-5 mt-5" />

              <p className="lead mt-4 sub-heading-txt">
                Oops! Something went wrong with your payment. Please try again.
              </p>

              <Button
                variant="primary"
                href="/"
                className="mt-3 navigate-btn gradient-button"
              >
                Go to Home
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PaymentFailure;
