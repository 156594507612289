import React from "react";
import "./ProtectCard.css";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import GreenTickIcon from "../../assets/images/DimsMobile/GreenTick.svg";
import ProtectYourScreen from "../../assets/images/DimsMobile/MobileProtectIcons.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function ProtectCard(props) {
  const navigate = useNavigate();

  // const isMobileOrTablet = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobileOrTablet = useMediaQuery({ maxWidth: "767px" });
  console.log(isMobileOrTablet);

  const handleImageClick = () => {
    navigate("/protect-form");
  };

  return (
    <div className="responsive-height">
      <Container fluid className="screen-protect-section">
        <Row className="align-items-center">
          {/* ****** Left Side: Text Content ****** */}
          <Col
            lg={6}
            md={12}
            className="text-content"
            // Use media query to detect if the screen width is below or equal to 768px (md breakpoint)
            style={{ cursor: isMobileOrTablet ? "pointer" : "default" }}
            // onClick={isMobileOrTablet ? navigate("/protect-form") : null}
            onClick={isMobileOrTablet ? () => handleImageClick() : null}
          >
            <div className="container">
              <p class="safeguard-heading-Txt">Safeguard your screen</p>
              <p class="dmi-screen-protect-heading-txt">
                with DMI Screen Protect
              </p>
            </div>

            <div className="container">
              <p className="paragraph-txt">Get a screen replacement plan for</p>
              <p className="paragraph-txt-two">as low as ₹699</p>
            </div>

            <div className="container btn-parent-container">
              <Button
                onClick={() => navigate("/protect-form")}
                variant="primary"
                className="protect-btn gradient-button"
              >
                {props?.btnLabel}
              </Button>
            </div>
          </Col>

          {/* ****** Right Side: Image Content ****** */}
          <Col
            lg={6}
            md={12}
            className="image-content" // Use media query to detect if the screen width is below or equal to 768px (md breakpoint)
            style={{ cursor: isMobileOrTablet ? "pointer" : "default" }}
            onClick={isMobileOrTablet ? () => handleImageClick() : null}
          >
            <Image
              src={ProtectYourScreen}
              alt="Broken Phone"
              className="img-fluid phone-image"
            />
          </Col>
        </Row>
      </Container>

      {/* ****** Feature Badges ****** */}
      <Container
        className="bottom-card"
        style={{ cursor: isMobileOrTablet ? "pointer" : "default" }}
        onClick={isMobileOrTablet ? () => handleImageClick() : null}
      >
        <Row className="text-center bottom-card-section">
          <Col className="mt-1" xs={5} md={3}>
            <Image
              src={GreenTickIcon}
              alt="GreenTickIcon"
              className="green-tick-icon"
            />
            <div className="label-txt">Coverage</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              upto ₹ 10,000
            </div>
          </Col>
          <Col className="mt-1" xs={5} md={3}>
            <Image
              src={GreenTickIcon}
              alt="GreenTickIcon"
              className="green-tick-icon"
            />
            <div className="label-txt">Water</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              Damages
            </div>
          </Col>
          <Col className="mt-1" xs={5} md={3}>
            <Image
              src={GreenTickIcon}
              alt="GreenTickIcon"
              className="green-tick-icon"
            />
            <div className="label-txt">Shattered</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              Screens
            </div>
          </Col>
          <Col className="mt-1" xs={5} md={3}>
            <Image
              src={GreenTickIcon}
              alt="GreenTickIcon"
              className="green-tick-icon"
            />
            <div className="label-txt">Accidental</div>
            <div className="label-txt" style={{ marginTop: -4 }}>
              Drops
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProtectCard;
