import React from "react";
import "./CustomerReview.css";
import { Card, Image } from "react-bootstrap";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"; // For star rating icons

const CustomerReview = ({ profilePic, name, review, rating }) => {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        stars.push(<FaStar key={i} color="gold" />);
      } else if (rating >= i - 0.5) {
        stars.push(<FaStarHalfAlt key={i} color="gold" />);
      } else {
        stars.push(<FaRegStar key={i} color="gold" />);
      }
    }
    return stars;
  };

  return (
    <div className="text-center">
      <Card style={{ width: "100%" }} className="review-card-container">
        <Card.Body>
          <div className="d-flex align-items-center mb-2">
            <Image
              width="50"
              height="50"
              roundedCircle
              src={profilePic}
              className="me-3 profile-pictures"
              style={{ objectFit: "contain" }}
            />
            <Card.Title className="user-name-txt">{name}</Card.Title>
          </div>

          <Card.Text className="user-description-txt">
            {review.split("\n").map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Card.Text>
          <div className="d-flex ">{renderStars(rating)}</div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomerReview;
