// const BASE_URL = "http://portal.acrossassist.com/api/partner/v1/apb/";

export const APIS = {
  GET_APB_URL:
    "https://cors-anywhere.herokuapp.com/https://stag.acrossassist.com/api/partner/v1/apb/GetUrl?Code=",
  APB_URL:
    // "http://portal.acrossassist.com/api/partner/v1/apb/SaveAirtelPaymentBankDetails",
    "https://cors-anywhere.herokuapp.com/http://portal.acrossassist.com/api/partner/v1/apb/SaveAirtelPaymentBankDetails",

  RISKCOVRY_URL:
    "https://portal.acrossassist.com/api/partner/v1/apb/SaveRiskyCoverDetails",

  // DIMS_HEALTH_URL :,
};

// ****** DMI Finance Apis ******
export const DMI_FINANCE_APIS = {
  GET_PLAN_URL: "https://portal.acrossassist.com/api/partner/v1/dmi/GetPlan",
  GET_BRAND_LIST_URL:
    "https://portal.acrossassist.com/api/partner/v1/dmi/GetBrands",
  SAVE_DMI_DETAILS_URL:
    "https://portal.acrossassist.com/api/partner/v1/dmi/SaveDMIDetails",
  SEND_DMI_PAYMENT_REQUEST_URL:
    "https://portal.acrossassist.com/api/partner/v1/dmi/SendDMIPayemtRequest",
  GET_DMI_PAYMENT_RESPONSE:
    "https://portal.acrossassist.com/api/partner/v1/dmi/GetDmiPaymentResponse?encResp=",
};
